import {
  invalidNumberValidator,
  useCommonStringValidator,
} from 'valtech-core/common/form/validators';
import {
  CURRENCY_FTL,
  ENTITY_FTL,
  ERROR_INVALID_TITLE_FTL,
  ERROR_NUMBER_FORMAT_FTL,
  ERROR_REQUIRED_FTL,
  FEE_FTL,
  MODEL_FTL,
  STATUS_FTL,
  TITLE_FTL,
} from 'valtech-core/common/ftl';
import { ActivationStatus } from 'valtech-core/common/gql/generated';
import { CurrencyEnum } from 'valtech-core/common/types';

export enum Rate {
  ID = 'id',
  Title = 'title',
  Model = 'model',
  Currency = 'ccy',
  Fee = 'fee',
  Status = 'status',
  Entity = 'entity',
}

export type RatesStatus = {
  id: number;
  title: string;
  value: ActivationStatus;
};

export type ModelStatus = {
  id: number;
  title: string;
  value: string;
};

export type Rates = {
  id: number;
  title: string;
};

export interface IRateTable {
  id: number;
  title: string;
  ccy: string;
  fee: number;
  model: string;
  status: string;
  entity?: string;
}

export const defaultValues: Omit<IRateTable, 'id'> = {
  title: '',
  model: '',
  ccy: CurrencyEnum.EUR,
  fee: 0,
  status: '',
  entity: '',
};

export type Currency = {
  id: number;
  title: string;
  value: string;
};

export type Entity = {
  id: number | string;
  title: string;
  value: string;
  status: string;
};

export const headData = [
  { l10n: TITLE_FTL, model: Rate.Title, sortBy: Rate.Title },
  { l10n: MODEL_FTL, model: Rate.Model, sortBy: Rate.Model },
  { l10n: CURRENCY_FTL, model: Rate.Currency, sortBy: Rate.Currency },
  { l10n: FEE_FTL, model: Rate.Fee, sortBy: Rate.Fee },
  { l10n: ENTITY_FTL, model: Rate.Entity, sortBy: Rate.Entity },
  { l10n: STATUS_FTL, model: Rate.Status, sortBy: Rate.Status },
];

export const titleValidator = useCommonStringValidator({
  required: ERROR_REQUIRED_FTL,
  invalid: ERROR_INVALID_TITLE_FTL,
});

export const requiredStringValidator = useCommonStringValidator({
  required: ERROR_REQUIRED_FTL,
});

export const feeValidator = invalidNumberValidator({
  invalid: ERROR_NUMBER_FORMAT_FTL,
});
