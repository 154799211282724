import React, { FC, useCallback, useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLocalization } from '@fluent/react';
import { useBudgetingState } from 'common/apolloState/budgeting';
import { CLIENT_DEFAULT } from 'common/constants';
import { ConsultantModelEnum, IEditBudgetingFormProps, TimeOptionsEnum } from 'common/types';
import { getCalendarRangeByYear } from 'common/utils/getCalendarRangeByYear';
import AutocompleteField from 'form/AutocompleteField';
import CheckboxField from 'form/CheckboxField';
import DataPickerField from 'form/DataPickerField';
import SelectFiled from 'form/SelectField';
import TextField from 'form/TextField';
import { useEditBillingReportErrorsByID } from 'src/pages/SingleBilling/EditBillingReportErrorsByID/EditBillingReportErrorsByID.hook';

import moment, { Moment } from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  lettersOnlyValidator,
  notesLimitValidator,
  requiredStringValidator,
  validateNumber,
} from 'valtech-core/common/form/validators';
import {
  ADD_FTL,
  ALLOCATION_FTL,
  CANCEL_FTL,
  CLIENT_FTL,
  CONSULTANT_FTL,
  COST_OF_OVERHEAD_FTL,
  DELETE_FTL,
  END_DATE_FTL,
  FEE_FTL,
  HOLIDAY_CALENDAR_FTL,
  HOURS_FTL,
  HOURS_OR_PERCANTAGE_FTL,
  MODEL_FTL,
  NOTES_FTL,
  RATE_FTL,
  SAVE_FTL,
  SHOW_IN_BUDGET_FTL,
  START_DATE_FTL,
} from 'valtech-core/common/ftl';
import { useGetRateForConsultantLazyQuery } from 'valtech-core/common/gql/generated';
import { FieldControl, Maybe } from 'valtech-core/common/types';
import Button from 'valtech-core/ui/Button';
import SpinnerOverlay from 'valtech-core/ui/SpinnerOverlay';

import { useHandleAssignment } from './AssignmentForm.hooks';

import {
  CUSTOM_TIER,
  checkDates,
  checkFeeValidity,
  getDefaultDate,
  getDefaultDateWithMonth,
  getRateKey,
  getRateLabel,
} from '../forms.utils';
import { compareAssignmentData, formattedAssignmentData } from './AssignmentForm.utils';

import { EFormFieldName } from '../form.types';
import { selectTypeTime } from './AssignmentForm.const';
import { ADD_TITLE_FTL, EDIT_TITLE_FTL } from './AssignmentFrom.ftl';

export interface IAssignmentFormProps {
  consultant: { id: number; label: string };
  client: string;
  hoursOrPercantage?: string;
  model: string;
  allocation?: number | null;
  hours?: number | null;
  rate: string;
  fee: number;
  startDate: Moment;
  endDate: Moment;
  showInBudget?: boolean;
  notes: string;
  holidayCalendarId: number;
  costOfOverhead: number;
  datePickerMinStartDate?: Moment;
  datePickerMinEndDate?: Moment;
  defaultCalendarDate?: {
    month: Maybe<number>;
    year: Maybe<number>;
  };
  errorId?: number;
  billingId?: number;
}

const AssignmentForm: FC<IEditBudgetingFormProps<IAssignmentFormProps>> = ({
  onClose,
  initialValues,
  defaultValues,
  assignmentId,
  billingReportId,
  hasDelete,
  fromBilling,
  isDisabledEditOnBilling = true,
  ccy,
  icboTemplateId,
  createFromWarning = false,
}) => {
  const { l10n } = useLocalization();
  const DEFAULT_INPUT_VALUE = 0;

  const [selectedModel, setSelectedModel] = useState<ConsultantModelEnum>(
    initialValues?.model
      ? (initialValues.model as ConsultantModelEnum)
      : defaultValues?.model
      ? (defaultValues.model as ConsultantModelEnum)
      : ConsultantModelEnum.Dedicated,
  );
  const [isRateDisabled, setRateDisabled] = useState<boolean>(true);
  const [rateLabelDefault, setRateLabelDefault] = useState('');
  const [selectedConsultantEntity, setSelectedConsultantEntity] = useState<string | undefined>('');

  const { control, handleSubmit, formState, watch, setValue, setError } =
    useForm<IAssignmentFormProps>({
      defaultValues: {
        ...(initialValues ? initialValues : defaultValues),
      },
    });

  useEffect(() => {
    setValue(
      EFormFieldName.HoursOrPercantage,
      formState.defaultValues?.hours ? TimeOptionsEnum.Hours : TimeOptionsEnum.Percantage,
    );
  }, []);

  const isEditMode = Boolean(initialValues);
  const shouldFieldBeDisabled = isEditMode && isDisabledEditOnBilling;
  const dirtyRate = formState.dirtyFields.hasOwnProperty(EFormFieldName.Rate);
  const hoursOrPercentageValue = watch(EFormFieldName.HoursOrPercantage);
  const isHoursTimeAndMaterialModel =
    selectedModel === ConsultantModelEnum.TimeAndMaterial &&
    hoursOrPercentageValue === TimeOptionsEnum.Hours;

  const { financialYear, currency, settingId: budgetingSettingId } = useBudgetingState();
  const { editBillingReportErrorsByID } = useEditBillingReportErrorsByID();
  const {
    clientsList,
    consultantsList,
    rateList,
    holidayCalendarList,
    modelList,
    createAssignment,
    updateAssignment,
    deleteAssignment,
    updateNotes,
    rates,
    loading,
  } = useHandleAssignment({ selectedConsultantEntity });

  const [getRateForConsultant] = useGetRateForConsultantLazyQuery();

  const selectedConsultant = control._formValues[EFormFieldName.Consultant]; // Access the current form value

  const formSubmit: SubmitHandler<IAssignmentFormProps> = useCallback(
    data => {
      const isValidPeriod = checkDates<IAssignmentFormProps>({
        data,
        dateKeys: [EFormFieldName.StartDate, EFormFieldName.EndDate],
        setError,
      });

      if (checkFeeValidity({ data, rates, setError })) return;
      if (isValidPeriod) {
        if (initialValues) {
          compareAssignmentData(
            formattedAssignmentData(initialValues as IAssignmentFormProps),
            formattedAssignmentData(data),
          )
            ? updateNotes(Number(assignmentId), data.notes).then(success => success && onClose())
            : updateAssignment({
                assignmentId,
                data,
                fromBilling,
                billingReportId,
                ccy,
                icboTemplateId,
              }).then(success => success && onClose());
        } else {
          createAssignment(data, fromBilling).then(success => success && onClose());
          if (createFromWarning && defaultValues?.errorId && defaultValues?.billingId) {
            editBillingReportErrorsByID(
              defaultValues.errorId,
              Number(defaultValues.billingId),
              false,
              true,
            );
          }
        }
      }
    },
    [onClose, rates],
  );

  function fieldWatcher(filed: keyof IAssignmentFormProps) {
    const fieldValue = watch(filed);

    return fieldValue;
  }

  useEffect(() => {
    if (selectedConsultant?.id) {
      // If already selected, find the consultant and set the entity
      const consultant = consultantsList.find(
        consultant => consultant.id === selectedConsultant.id,
      );
      setSelectedConsultantEntity(consultant?.entity);
    }
  }, [consultantsList, selectedConsultant]);

  useEffect(() => {
    const pickRateBasedOnModel = (model: ConsultantModelEnum): void => {
      const consultantId = watch(EFormFieldName.Consultant)?.id || defaultValues?.consultant?.id;
      const selectedStartDate = watch(EFormFieldName.StartDate);
      const costOfOverhead = Number(watch(EFormFieldName.CostOfOverhead));

      const consultant = consultantsList.find(consultant => consultant.id === consultantId);

      let rateMonth = Number(
        selectedStartDate
          ? moment(selectedStartDate as Moment).month() + 1
          : defaultValues?.defaultCalendarDate
          ? defaultValues?.defaultCalendarDate?.month
          : moment().month() + 1,
      );

      if (rateMonth < 1 || rateMonth > 12) rateMonth = 1;

      getRateForConsultant({
        variables: {
          projectCcy: currency,
          budgetingSettingId: Number(budgetingSettingId),
          consultantId: Number(consultant?.id),
          costOfOverhead,
          numberOfMonth: rateMonth,
          rateModel: model,
        },
      }).then(res => {
        const consultantRateRes = res.data?.getRateForConsultant;

        if (consultantRateRes) {
          setTimeout(() => {
            setValue(EFormFieldName.Rate, `${consultantRateRes.id}`);
          }, 100);
        }
      });
    };

    const subscription = watch((value, { name, type }) => {
      const hasConsultantChanged = name === EFormFieldName.Consultant && type === 'change';
      const hasStartDateChanged = name === EFormFieldName.StartDate && type === 'change';
      const hasCostOfOverheadChanged = name === EFormFieldName.CostOfOverhead && type === 'change';
      const hasModelUpdated = name === EFormFieldName.Model;
      const hasSelectedConsultant = value.consultant?.id;
      const newSelectedModel = value.model as ConsultantModelEnum;
      const costOfOverhead = Number(value.costOfOverhead);
      const hasRateUpdated = name === EFormFieldName.Rate;
      const selectedRate = value.rate;

      const rateLabel =
        !defaultValues?.consultant?.id && getRateLabel(rates, newSelectedModel, selectedRate);

      const rateLabelDefaultValues =
        defaultValues?.consultant?.id && getRateLabel(rates, newSelectedModel, selectedRate);
      setRateLabelDefault(rateLabelDefaultValues?.toString() ?? '');

      if (hasConsultantChanged) {
        const consultant = consultantsList.find(
          consultant => consultant.id === value.consultant?.id,
        );

        setSelectedConsultantEntity(consultant?.entity);
      }

      const hasFormChanged =
        hasConsultantChanged || hasModelUpdated || hasCostOfOverheadChanged || hasStartDateChanged;
      const hasAllRequiredValues =
        hasSelectedConsultant && newSelectedModel && (costOfOverhead || costOfOverhead === 0);

      if (hasFormChanged && hasAllRequiredValues) {
        rateLabel !== CUSTOM_TIER && pickRateBasedOnModel(newSelectedModel as ConsultantModelEnum);
      }

      if (
        hasConsultantChanged &&
        hasSelectedConsultant &&
        !hasModelUpdated &&
        !hasCostOfOverheadChanged &&
        !hasStartDateChanged
      ) {
        pickRateBasedOnModel(newSelectedModel as ConsultantModelEnum);
      }

      const shouldKeepInitialFeeOnEdit =
        initialValues?.fee &&
        newSelectedModel === initialValues?.model &&
        isEditMode &&
        rateLabel === CUSTOM_TIER;

      if (hasRateUpdated && selectedRate) {
        if (rateLabel === CUSTOM_TIER) {
          setRateDisabled(false);
        } else if (rateLabel !== CUSTOM_TIER && !isRateDisabled) {
          setRateDisabled(true);
        }

        setTimeout(() => {
          shouldKeepInitialFeeOnEdit
            ? setValue(EFormFieldName.Fee, initialValues?.fee ?? 0)
            : setValue(
                EFormFieldName.Fee,
                rates[getRateKey(newSelectedModel)].find(rate => rate.id === Number(selectedRate))
                  ?.fee ?? 0,
              );
        }, 100);
      }
    });

    if (defaultValues?.consultant?.id) {
      const initialModel = watch(EFormFieldName.Model) as ConsultantModelEnum;
      pickRateBasedOnModel(initialModel);
    }

    return () => subscription.unsubscribe();
  }, [watch, rateList, rates, isRateDisabled, consultantsList]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const hasModelChanged = name === EFormFieldName.Model && type === 'change';
      const newSelectedModel = value.model;

      if (hasModelChanged && newSelectedModel) {
        setSelectedModel(newSelectedModel as ConsultantModelEnum);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, rates, isRateDisabled]);

  useEffect(() => {
    const rateLabel = getRateLabel(
      rates,
      initialValues?.model as unknown as any,
      initialValues?.rate,
    );

    if (!isDisabledEditOnBilling) {
      rateLabel === CUSTOM_TIER && setRateDisabled(false);
    }

    if (shouldFieldBeDisabled) {
      rateLabel === CUSTOM_TIER && setRateDisabled(false);
    }
  }, [isDisabledEditOnBilling, rates, rateList]);

  useEffect(() => {
    if (!isEditMode && !createFromWarning) {
      const generalClientId = clientsList.find(client => client.title === CLIENT_DEFAULT)?.id;
      generalClientId && setValue(EFormFieldName.Client, `${generalClientId}`);
    }
  }, [clientsList]);

  const handleTimeOptionChange = (value: string) => {
    if (value === ConsultantModelEnum.Dedicated) {
      setValue(EFormFieldName.HoursOrPercantage, TimeOptionsEnum.Percantage);
    } else {
      setValue(EFormFieldName.HoursOrPercantage, TimeOptionsEnum.Hours);
    }
    setValue(EFormFieldName.Allocation, DEFAULT_INPUT_VALUE);
    setValue(EFormFieldName.Hours, DEFAULT_INPUT_VALUE);
  };

  const handleModelChange = (value: string) => {
    if (value === ConsultantModelEnum.Dedicated) {
      setValue(EFormFieldName.HoursOrPercantage, TimeOptionsEnum.Percantage);
    } else {
      setValue(EFormFieldName.HoursOrPercantage, TimeOptionsEnum.Hours);
    }
    setValue(EFormFieldName.Allocation, DEFAULT_INPUT_VALUE);
    setValue(EFormFieldName.Hours, DEFAULT_INPUT_VALUE);
  };

  const onCancel = useCallback(() => {
    onClose();
  }, []);

  const onDelete = () => {
    deleteAssignment(assignmentId).then(success => success && onClose());
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <SpinnerOverlay visible={loading} />
      <Box sx={{ mt: 2, mb: 5 }}>
        {isEditMode ? l10n.getString(EDIT_TITLE_FTL) : l10n.getString(ADD_TITLE_FTL)}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <AutocompleteField
            label={l10n.getString(CONSULTANT_FTL)}
            name={EFormFieldName.Consultant}
            control={control as unknown as FieldControl}
            data={consultantsList}
            disabled={isEditMode}
            validate={value => lettersOnlyValidator(value?.label)}
          />
        </Grid>

        <Grid item xs={4}>
          <SelectFiled
            label={l10n.getString(MODEL_FTL)}
            name={EFormFieldName.Model}
            data={modelList}
            disabled={!hasDelete && shouldFieldBeDisabled}
            validate={requiredStringValidator}
            control={control as unknown as FieldControl}
            onChange={e => {
              handleModelChange(e.target.value as string);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <SelectFiled
            label={l10n.getString(HOLIDAY_CALENDAR_FTL)}
            name={EFormFieldName.HolidayCalendarId}
            data={holidayCalendarList}
            validate={requiredStringValidator}
            disabled={!hasDelete && shouldFieldBeDisabled}
            control={control as unknown as FieldControl}
          />
        </Grid>

        <Grid item xs={4}>
          <SelectFiled
            label={l10n.getString(HOURS_OR_PERCANTAGE_FTL)}
            name={EFormFieldName.HoursOrPercantage}
            data={selectTypeTime}
            disabled={
              (!hasDelete && shouldFieldBeDisabled) ||
              selectedModel === ConsultantModelEnum.Dedicated
            }
            control={control as unknown as FieldControl}
            onChange={e => handleTimeOptionChange(e.target.value as string)}
          />
        </Grid>

        {selectedModel === ConsultantModelEnum.Dedicated && (
          <Grid item xs={4}>
            <TextField
              label={`${l10n.getString(ALLOCATION_FTL)}, %`}
              textProps={{
                type: 'number',
                disabled: shouldFieldBeDisabled,
              }}
              name={EFormFieldName.Allocation}
              validate={validateNumber}
              disabled={!hasDelete && shouldFieldBeDisabled}
              control={control as unknown as FieldControl}
            />
          </Grid>
        )}

        {selectedModel === ConsultantModelEnum.TimeAndMaterial && !isHoursTimeAndMaterialModel && (
          <Grid item xs={4}>
            <TextField
              label={`${l10n.getString(ALLOCATION_FTL)}, %`}
              textProps={{
                type: 'number',
                disabled: shouldFieldBeDisabled,
              }}
              name={EFormFieldName.Allocation}
              validate={validateNumber}
              disabled={!hasDelete && shouldFieldBeDisabled}
              control={control as unknown as FieldControl}
            />
          </Grid>
        )}

        {selectedModel === ConsultantModelEnum.TimeAndMaterial && isHoursTimeAndMaterialModel && (
          <Grid item xs={4}>
            <TextField
              label={l10n.getString(HOURS_FTL)}
              textProps={{
                type: 'number',
                disabled: shouldFieldBeDisabled,
              }}
              name={EFormFieldName.Hours}
              validate={validateNumber}
              disabled={!hasDelete && shouldFieldBeDisabled}
              control={control as unknown as FieldControl}
            />
          </Grid>
        )}

        <Grid item xs={4}>
          <TextField
            label={`${l10n.getString(COST_OF_OVERHEAD_FTL)}, ${currency}`}
            name={EFormFieldName.CostOfOverhead}
            textProps={{
              type: 'number',
            }}
            disabled={!hasDelete && shouldFieldBeDisabled}
            control={control as unknown as FieldControl}
          />
        </Grid>

        <Grid item xs={3}>
          <DataPickerField
            label={l10n.getString(START_DATE_FTL)}
            name={EFormFieldName.StartDate}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
            disabled={!hasDelete && shouldFieldBeDisabled}
            defaultCalendarMonth={
              defaultValues?.defaultCalendarDate
                ? getDefaultDateWithMonth(
                    defaultValues?.defaultCalendarDate?.month,
                    defaultValues?.defaultCalendarDate?.year,
                  )
                : getDefaultDate(financialYear)
            }
            minDate={
              defaultValues?.datePickerMinStartDate ||
              getCalendarRangeByYear(financialYear).firstDayOfYear
            }
            maxDate={
              defaultValues?.datePickerMinEndDate ||
              getCalendarRangeByYear(financialYear).lastDayOfYear
            }
          />
        </Grid>

        <Grid item xs={3}>
          <DataPickerField
            label={l10n.getString(END_DATE_FTL)}
            name={EFormFieldName.EndDate}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
            disabled={!fieldWatcher(EFormFieldName.StartDate)}
            minDate={
              fieldWatcher(EFormFieldName.StartDate)
                ? moment(fieldWatcher(EFormFieldName.StartDate) as string).startOf('day')
                : moment().startOf('day')
            }
            maxDate={
              defaultValues?.datePickerMinEndDate
                ? moment(defaultValues?.datePickerMinEndDate).endOf('day')
                : moment(financialYear, 'YYYY').endOf('year')
            }
            defaultCalendarMonth={
              fieldWatcher(EFormFieldName.StartDate)
                ? moment(fieldWatcher(EFormFieldName.StartDate) as string)
                    .startOf('month')
                    .format('YYYY-MM')
                : moment().startOf('month').format('YYYY-MM')
            }
          />
        </Grid>

        <Grid item xs={3}>
          <SelectFiled
            label={l10n.getString(RATE_FTL)}
            name={EFormFieldName.Rate}
            data={rateList[getRateKey(selectedModel)]}
            disabled={!hasDelete && shouldFieldBeDisabled}
            validate={requiredStringValidator}
            control={control as unknown as FieldControl}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            label={l10n.getString(FEE_FTL)}
            name={EFormFieldName.Fee}
            textProps={{
              type: 'number',
              disabled: !fieldWatcher(EFormFieldName.Rate) || isEditMode,
            }}
            disabled={rateLabelDefault !== CUSTOM_TIER && isRateDisabled}
            validate={dirtyRate ? validateNumber : undefined}
            control={control as unknown as FieldControl}
          />
        </Grid>

        {fromBilling && (
          <Grid item xs={12}>
            <CheckboxField
              label={l10n.getString(SHOW_IN_BUDGET_FTL)}
              name={EFormFieldName.ShowInBudget}
              control={control as unknown as FieldControl}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <SelectFiled
            label={l10n.getString(CLIENT_FTL)}
            name={EFormFieldName.Client}
            data={clientsList}
            disabled={!clientsList.length || shouldFieldBeDisabled}
            control={control as unknown as FieldControl}
            validate={requiredStringValidator}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name={EFormFieldName.Notes}
            control={control as unknown as FieldControl}
            label={l10n.getString(NOTES_FTL)}
            textProps={{
              multiline: true,
              rows: 5,
            }}
            validate={notesLimitValidator}
          />
        </Grid>
      </Grid>

      <Stack direction='row' spacing={2} sx={{ mb: 1, mt: 6 }}>
        <Button
          onClick={handleSubmit(formSubmit)}
          disabled={!formState.isDirty || loading}
          variant='contained'>
          {isEditMode ? l10n.getString(SAVE_FTL) : l10n.getString(ADD_FTL)}
        </Button>

        {isEditMode && hasDelete && (
          <Button
            disabled={loading}
            onClick={onDelete}
            variant='outlined'
            type='button'
            color='error'>
            {l10n.getString(DELETE_FTL)}
          </Button>
        )}

        <Button onClick={onCancel} type='button'>
          {l10n.getString(CANCEL_FTL)}
        </Button>
      </Stack>
    </form>
  );
};

export default AssignmentForm;
